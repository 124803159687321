import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Constant from "../Constant";
import { getAccessToken } from "../../hooks/AccessToken";

class RegistrationForms extends Component {
  state = {
    forms: [],
  };

  fetchRegistrationForms = async () => {
    const token = getAccessToken();
    console.log("Access token:", token);
    try {
      const response = await Axios.get(
        Constant.apiBasePath + "registration-forms/get-all",
        {
          headers: { token },
        }
      );
      console.log("API response:", response.data);
      if (response.data.message === "Forms retrieved successfully") {
        console.log("Updating state with forms:", response.data.data);
        this.setState({ forms: response.data.data }, () => {
          console.log("State updated successfully:", this.state.forms);
        });
      } else {
        alert(response.data.message);
      }
    } catch (err) {
      console.error("Error in API call:", err);
    }
  };

  handleDelete = async (id) => {
    const token = getAccessToken();
    if (window.confirm("Are you sure you want to delete this form?")) {
      try {
        const response = await Axios.delete(
          Constant.apiBasePath + `registration-forms/${id}`,
          {
            headers: { token },
          }
        );
        if (response.data.status === Constant.statusSuccess) {
          alert("Form deleted successfully!");
          this.fetchRegistrationForms();
        } else {
          alert(response.data.message);
        }
      } catch (err) {
        console.error("Error deleting registration form:", err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  componentDidMount() {
    this.fetchRegistrationForms();
  }

  render() {
    const { forms } = this.state;

    return (
      <div className="content-page">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title-heading">Registration Forms</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link
                    to="/add-registration-form"
                    className="btn btn-primary mb-3"
                  >
                    Add New Form
                  </Link>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {/* <th>ID</th> */}
                        <th>Name</th>
                        <th>description</th>
                        <th>Success Msg</th>
                        <th>Event Type</th>
                        <th>Questions</th>
                        <th>Image</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
  {forms.map((form) => (
    <tr key={form._id}>
      {/* <td>{form._id}</td> */}
      <td>{form.name}</td>
      <td>{form.description}</td>
      <td>{form.successMsg}</td>
      <td>{form.eventType}</td>
      <td>{form.questions.length}</td>
      <td>
        {form.thumbnail && (
          <img
            src={form.thumbnail}
            alt={`${form.name} Preview`}
            style={{ width: "100px", height: "auto" }}
          />
        )}
      </td>
      <td>
        <Link
          to={`/registration-form-questions/${form._id}`}
          className="btn btn-info"
        >
          Questions
        </Link>
        <Link
          to={`/user-response/${form._id}`}
          className="btn btn-info"
        >
          User response
        </Link>
        <Link
          to={`/edit-registration-form/${form._id}`}
          className="btn btn-warning"
        >
          Edit
        </Link>
        <button
          onClick={() => this.handleDelete(form._id)}
          className="btn btn-danger"
        >
          Delete
        </button>
      </td>
    </tr>
  ))}
</tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistrationForms;

import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../../css/style.css";
import Constant from "../Constant";
import { getAccessToken } from "../../hooks/AccessToken";

class AddUpdateRegistrationForm extends Component {
  state = {
    name: "",
    eventType: "",
    successMsg: "",
    description: "",
    questions: [],
    isEditing: true,
    thumbnail: "", 
    ERROR: true,
    eventOptions: [],
  };

  handleChange = async (e) => {
    const { id, value } = e.target;
    if (id === "name") {
      this.setState({ name: value });
    } else if (id === "eventType") {
      this.setState({ eventType: value });
    } else if (id === "description") {
      this.setState({ description: value });
    } else if (id === "successMsg") {
      this.setState({ successMsg: value });
    } else if (id === "thumbnail") {
      this.setState({ thumbnail: value });
    } else {
      this.setState((prevState) => ({
        questions: prevState.questions.map((question, index) =>
          index === parseInt(id.split("-")[1])
            ? { ...question, [e.target.name]: value }
            : question
        ),
      }));
    }
  };

  handleAddQuestion = () => {
    this.setState((prevState) => ({
      questions: [
        ...prevState.questions,
        {
          questionText: "",
          inputType: "",
          isRequired: false,
          options: [],
        },
      ],
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, description, successMsg, eventType, questions, thumbnail } =
      this.state;
    const token = getAccessToken();
    const id = this.props.match.params.id;
    if (!id && (!name || !eventType || !description || !thumbnail)) {
      alert("Name, description, event type, and thumbnail are required!");
      return;
    }


    try {
      const payload = { name, eventType, description, successMsg, questions, thumbnail };

      let response;
      if (id) {
        response = await Axios.patch(
          Constant.apiBasePath + `registration-forms/${id}`,
          payload,
          {
            headers: { token },
          }
        );
      } else {
        response = await Axios.post(
          Constant.apiBasePath + "registration-forms",
          payload,
          {
            headers: { token },
          }
        );
      }

      if (response.data.message === "Form created successfully") {
        alert("Registration form created successfully!");
        
      } else {
        alert(response.data.message);
      }
      this.props.history.push("/registeration-form");
    } catch (err) {
      console.error("Error while submitting the form:", err);
      alert("Something went wrong. Please try again.");
    }
  };


  handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const token = getAccessToken();
    const formData = new FormData();
    formData.append("r-form", file);

    try {
      const response = await Axios.post(
        Constant.apiBasePath + "registration-forms/uploadFileOnS3",
        formData,
        {
          headers: {
            token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      console.log("Image uploaded successfully:", response.data);
      if (response.data.status === "Success") {
        const uploadedImageUrl = response.data.location;
        this.setState({ thumbnail: uploadedImageUrl });
        alert("Image uploaded successfully!");
      } else {
        alert(response.message);
      }
    } catch (err) {
      console.error("Error uploading image:", err);
      alert("Image upload failed. Please try again.");
    }
  };

  fetchRegistrationFormById = async (id) => {
    const token = getAccessToken();
    try {
      const response = await Axios.get(
        Constant.apiBasePath + `registration-forms/${id}`,
        {
          headers: { token },
        }
      );
      const { data } = response.data;
      if (response.data.message === "Form retrieved successfully") {
        this.setState({
          name: data.name,
          description: data.description,
          successMsg: data.successMsg,
          eventType: data.eventType,
          thumbnail: data.thumbnail,
          isEditing: true,
        });
      } else {
        alert(response.data.message);
      }
    } catch (err) {
      console.error("Error fetching registration form:", err);
      alert("Something went wrong. Please try again later.");
    }
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.fetchRegistrationFormById(id);
    } else {
      this.setState({ isEditing: false });
    }

    const eventOptions = [
      { id: "contest", name: "Contest" },
      { id: "offline-open-mic", name: "Offline Open Mic" },
      { id: "online-open-mic", name: "Online Open Mic" },
      { id: "fest", name: "Fest" },
    ];
    this.setState({ eventOptions });
  }

  render() {
    const {
      name,
      successMsg,
      description,
      eventType,
      questions,
      thumbnail,
      eventOptions,
    } = this.state;

    return (
      <div className="content-page">
        <div className="content">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={this.handleChange}
                placeholder="Enter form name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                className="form-control"
                value={description}
                onChange={this.handleChange}
                placeholder="Enter description"
              />
            </div>
            <div className="form-group">
              <label htmlFor="successMsg">Success Message</label>
              <input
                type="text"
                id="successMsg"
                className="form-control"
                value={successMsg}
                onChange={this.handleChange}
                placeholder="Enter success message"
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventType">Event Type</label>
              <select
                id="eventType"
                className="form-control"
                value={eventType}
                onChange={this.handleChange}
              >
                <option value="">Select Event Type</option>
                {eventOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="thumbnail">Thumbnail</label>
              <input
                type="file"
                id="thumbnail"
                className="form-control"
                onChange={this.handleImageUpload}
              />
              {thumbnail && (
                <img
                  src={thumbnail}
                  alt="Uploaded Thumbnail"
                  style={{ marginTop: "10px", width: "150px" }}
                />
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default AddUpdateRegistrationForm;

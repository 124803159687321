import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

const INPUT_TYPES = {
    TEXT: "text",
    RADIO: "radio",
    CHECKBOX: "checkbox",
    SELECT: "select",
};

class FormQuestions extends Component {
    state = {
        questions: [],
        newQuestion: {
            questionText: "",
            inputType: INPUT_TYPES.TEXT,
            options: [],
            isRequired: false,
            allowOther: false,
        },
        editingQuestion: null,
        loading: true,
        error: null,
        isAdding: false,
    };

    fetchQuestions = async () => {
        const { id } = this.props.match.params;
        const token = getAccessToken();
        try {
            const response = await Axios.get(`${Constant.apiBasePath}registration-forms/${id}`, {
                headers: { token },
            });
            if (response.data.message === "Form retrieved successfully") {
                this.setState({ questions: response.data.data.questions, loading: false });
            } else {
                this.setState({ error: response.data.message, loading: false });
            }
        } catch (err) {
            console.error("Error fetching form questions:", err);
            this.setState({ error: "Failed to fetch questions. Please try again.", loading: false });
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        const currentQuestion = this.state.editingQuestion ? "editingQuestion" : "newQuestion";
        this.setState((prevState) => ({
            [currentQuestion]: { ...prevState[currentQuestion], [name]: value },
        }));
    };

    handleOptionChange = (index, value, isEditing = false) => {
        const currentQuestion = isEditing ? "editingQuestion" : "newQuestion";
        this.setState((prevState) => {
            const options = [...prevState[currentQuestion].options];
            options[index] = value;
            return {
                [currentQuestion]: { ...prevState[currentQuestion], options },
            };
        });
    };

    addOption = (isEditing = false) => {
        const currentQuestion = isEditing ? "editingQuestion" : "newQuestion";
        this.setState((prevState) => ({
            [currentQuestion]: {
                ...prevState[currentQuestion],
                options: [...prevState[currentQuestion].options, ""],
            },
        }));
    };

    removeOption = (index, isEditing = false) => {
        const currentQuestion = isEditing ? "editingQuestion" : "newQuestion";
        this.setState((prevState) => {
            const options = prevState[currentQuestion].options.filter((_, i) => i !== index);
            return {
                [currentQuestion]: { ...prevState[currentQuestion], options },
            };
        });
    };

    submitEditQuestion = async () => {
        const { id } = this.props.match.params;
        const token = getAccessToken();
        const { editingQuestion } = this.state;

        try {
            const response = await Axios.patch(
                `${Constant.apiBasePath}registration-forms/edit-question/${id}/${editingQuestion._id}`,
                { updatedQuestion: editingQuestion },
                { headers: { token } }
            );
            if (response.data.message === "Question updated successfully") {
                alert("Question updated successfully!");
                this.fetchQuestions();
                this.setState({ editingQuestion: null });
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error("Error editing question:", err);
            alert("Failed to edit question. Please try again.");
        }
    };

    editQuestion = (question) => {
        this.setState({ editingQuestion: { ...question } });
    };

    cancelEdit = () => {
        this.setState({ editingQuestion: null });
    };

    componentDidMount() {
        this.fetchQuestions();
    }

    render() {
        const { questions, newQuestion, editingQuestion, loading, error, isAdding } = this.state;

        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Form Questions</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    {!editingQuestion && (
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => this.setState({ isAdding: true })}
                                        >
                                            Add Question
                                        </button>
                                    )}
                                    {editingQuestion ? (
                                        <div className="mb-3">
                                            <h5>Edit Question</h5>
                                            <div>
                                                <label>Question Text:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="questionText"
                                                    value={editingQuestion.questionText}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div>
                                                <label>Input Type:</label>
                                                <select
                                                    className="form-control"
                                                    name="inputType"
                                                    value={editingQuestion.inputType}
                                                    onChange={this.handleInputChange}
                                                >
                                                    {Object.values(INPUT_TYPES).map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {["radio", "checkbox", "select"].includes(editingQuestion.inputType) && (
                                                <div>
                                                    <label>Options:</label>
                                                    {editingQuestion.options.map((option, index) => (
                                                        <div key={index} className="d-flex mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={option}
                                                                onChange={(e) =>
                                                                    this.handleOptionChange(index, e.target.value, true)
                                                                }
                                                            />
                                                            <button
                                                                className="btn btn-danger ml-2"
                                                                onClick={() => this.removeOption(index, true)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={() => this.addOption(true)}
                                                    >
                                                        Add Option
                                                    </button>
                                                </div>
                                            )}
                                            <div className="form-check mt-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="isRequired"
                                                    checked={editingQuestion.isRequired}
                                                    onChange={(e) =>
                                                        this.handleInputChange({
                                                            target: {
                                                                name: "isRequired",
                                                                value: e.target.checked,
                                                            },
                                                        })
                                                    }
                                                />
                                                <label className="form-check-label">Required</label>
                                            </div>
                                            <button
                                                className="btn btn-success mt-3"
                                                onClick={this.submitEditQuestion}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-secondary mt-3 ml-2"
                                                onClick={this.cancelEdit}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>Input Type</th>
                                                    <th>Options</th>
                                                    <th>Allow Other</th>
                                                    <th>Required</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {questions.map((q) => (
                                                    <tr key={q._id}>
                                                        <td>{q.questionText}</td>
                                                        <td>{q.inputType}</td>
                                                        <td>{q.options.join(", ") || "N/A"}</td>
                                                        <td>{q.allowOther ? "Yes" : "No"}</td>
                                                        <td>{q.isRequired ? "Yes" : "No"}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-warning mr-2"
                                                                onClick={() => this.editQuestion(q)}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() => this.deleteQuestion(q._id)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormQuestions;

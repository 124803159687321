import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import Constant from "../Constant";
import { getAccessToken } from "../../hooks/AccessToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class ManageMeetings extends Component {
  state = {
    meetingsList: [],
    pageNo: 1,
    totalMeetings: 0,
    meetingNumber: "",
    passWord: "",
    sdkKey: "",
    sdkSecret: "",
    startTime: "",
    endTime: "",
    name: "",
    description: "",
    thumbnail: "",
    editingMeeting: null,
    showPopup: false,
  };

  componentDidMount() {
    this.fetchMeetings();
  }

  fetchMeetings = async () => {
    try {
      const token = getAccessToken();
      const response = await Axios.get(
        `${Constant.apiBasePath}meeting/get-all`,
        {
          headers: {
            token: token,
          },
        }
      );
      const { meetings } = response.data;
      this.setState({
        meetingsList: Array.isArray(meetings) ? meetings : [],
      });
    } catch (error) {
      console.error("Error fetching meetings:", error);
      this.setState({ meetingsList: [] });
    }
  };

  togglePopup = (meeting = null) => {
    this.setState((prevState) => ({
      meetingNumber: meeting ? meeting.meetingNumber : "",
      passWord: meeting ? meeting.passWord : "",
      sdkKey: meeting ? meeting.sdkKey : "",
      sdkSecret: meeting ? meeting.sdkSecret : "",
      startTime: meeting ? meeting.startTime : "",
      endTime: meeting ? meeting.endTime : "",
      name: meeting ? meeting.name : "",
      description: meeting ? meeting.description : "",
      thumbnail: meeting ? meeting.thumbnail : "",
      editingMeeting: meeting,
      showPopup: !prevState.showPopup,
    }));
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const token = getAccessToken();
        const formData = new FormData();
        formData.append("story", file);

        const response = await Axios.post(
          "https://api.storytent.in/api/story/uploadFileOnS3",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              token: token,
            },
          }
        );

        if (response.data.status === "Success") {
          this.setState({ thumbnail: response.data.location });
        } else {
          console.error("Error uploading thumbnail:", response.data.message);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  addOrUpdateMeeting = async () => {
    try {
      const {
        meetingNumber,
        passWord,
        sdkKey,
        sdkSecret,
        startTime,
        endTime,
        name,
        description,
        thumbnail,
        editingMeeting,
      } = this.state;

      if (
        !meetingNumber.trim() ||
        !passWord.trim() ||
        !sdkKey.trim() ||
        !sdkSecret.trim() ||
        !startTime.trim() ||
        !endTime.trim() ||
        !name.trim() ||
        !description.trim() ||
        !thumbnail.trim()
      ) {
        alert("All fields are required");
        return;
      }

      const token = getAccessToken();
      let response;

      const meetingData = {
        meetingNumber,
        passWord,
        sdkKey,
        sdkSecret,
        startTime,
        endTime,
        name,
        description,
        thumbnail,
      };

      if (editingMeeting) {
        response = await Axios.put(
          `${Constant.apiBasePath}meeting/${editingMeeting._id}`,
          meetingData,
          { headers: { token: token } }
        );
      } else {
        response = await Axios.post(
          `${Constant.apiBasePath}meeting`,
          meetingData,
          { headers: { token: token } }
        );
      }

      const updatedMeeting = response.data?.updatedMeeting;
      const meeting = response.data?.meeting;

      if (updatedMeeting && updatedMeeting._id) {
        this.setState((prevState) => ({
          meetingsList: prevState.meetingsList.map((meeting) =>
            meeting._id === updatedMeeting._id ? updatedMeeting : meeting
          ),
          meetingNumber: "",
          passWord: "",
          sdkKey: "",
          sdkSecret: "",
          startTime: "",
          endTime: "",
          name: "",
          description: "",
          thumbnail: "",
          editingMeeting: null,
          showPopup: false,
        }));
      } else if (meeting && meeting._id) {
        this.setState((prevState) => ({
          meetingsList: [...prevState.meetingsList, meeting],
          meetingNumber: "",
          passWord: "",
          sdkKey: "",
          sdkSecret: "",
          startTime: "",
          endTime: "",
          name: "",
          description: "",
          thumbnail: "",
          editingMeeting: null,
          showPopup: false,
        }));
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error adding/updating meeting:", error);
    }
  };

  deleteMeeting = async (meetingId) => {
    try {
      const token = getAccessToken();
      const response = await Axios.delete(
        `${Constant.apiBasePath}meeting/${meetingId}`,
        { headers: { token: token } }
      );

      if (response.status === 200) {
        this.setState((prevState) => ({
          meetingsList: prevState.meetingsList.filter(
            (meeting) => meeting._id !== meetingId
          ),
        }));
        alert("Meeting deleted successfully");
      } else {
        console.error("Failed to delete meeting:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting meeting:", error);
    }
  };

  toggleMeetingStatus = async (meetingId) => {
    try {
      const token = getAccessToken();
      const response = await Axios.post(
        `${Constant.apiBasePath}meeting/status/${meetingId}`,
        { meetingId },
        { headers: { token: token } }
      );
  
      if (response.message === "Meeting status updated to inactive") {
        
        const updatedMeeting = response.data?.meeting;
        this.setState((prevState) => ({
          meetingsList: prevState.meetingsList.map((meeting) =>
            meeting._id === updatedMeeting._id ? updatedMeeting : meeting
          ),
        }));
        alert("Meeting status updated successfully");
         
      } else {
        console.error("Failed to update status:", response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  render() {
    const {
      meetingsList,
      pageNo,
      totalMeetings,
      showPopup,
      meetingNumber,
      passWord,
      sdkKey,
      sdkSecret,
      startTime,
      endTime,
      name,
      description,
      thumbnail,
    } = this.state;
    const meetingsPerPage = Constant.perPage;
    const currentIndex = (pageNo - 1) * meetingsPerPage;

    return (
      <div className="content-page">
        <div className="row">
          <div className="col-6">
            <h4>Manage Meetings</h4>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-info" onClick={() => this.togglePopup()}>
              Add Meeting
            </button>
          </div>
        </div>
        {showPopup && (
          <div style={popupContainerStyle}>
            <div style={popupStyle}>
              <h4>{this.state.editingMeeting ? "Edit Meeting" : "Add Meeting"}</h4>
              <input
                type="text"
                name="meetingNumber"
                placeholder="Meeting Number"
                value={meetingNumber}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="text"
                name="passWord"
                placeholder="Password"
                value={passWord}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="text"
                name="sdkKey"
                placeholder="SDK Key"
                value={sdkKey}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="text"
                name="sdkSecret"
                placeholder="SDK Secret"
                value={sdkSecret}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="datetime-local"
                name="startTime"
                value={startTime}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="datetime-local"
                name="endTime"
                value={endTime}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="text"
                name="name"
                placeholder="Meeting Name"
                value={name}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <textarea
                name="description"
                placeholder="Meeting Description"
                value={description}
                onChange={this.handleInputChange}
                style={textareaStyle}
              />
              <input
                type="file"
                name="thumbnail"
                accept="image/*"
                onChange={this.handleFileChange}
                style={inputStyle}
              />
              <div>{thumbnail && <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: "100%" }} />}</div>
              <div style={{ marginTop: "10px" }}>
                <button
                  className="btn btn-success"
                  onClick={this.addOrUpdateMeeting}
                >
                  Save
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => this.togglePopup()}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
              {/* <th>#</th> */}
                <th>Meeting No</th>
                <th>Password</th>
                <th>Name</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>SDK Key</th>
                
              <th>thumbnail</th>
              <th>SDK Secret</th>
              <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {meetingsList.map((meeting, index) => (
                <tr key={index}>
                  <td>{meeting.meetingNumber}</td>
                  <td>{meeting.passWord}</td>
                  <td>{meeting.name}</td>
                  <td>{meeting.startTime}</td>
                  <td>{meeting.endTime}</td>
                  <td>{meeting.sdkKey}</td>
                  
                  <td>
                    {meeting.thumbnail ? (
                      <img src={meeting.thumbnail} alt="Thumbnail" style={{ maxWidth: "100px" }} />
                    ) : (
                      <span>No Thumbnail</span>
                    )}
                  </td>
                  <td>{meeting.sdkSecret}</td>
                  
                  <td>
                    <button
                      className={`btn ${
                        meeting.status === true || meeting.status === "true" // Handles both boolean and string
                          ? "btn-success"
                          : "btn-warning"
                      }`}
                      onClick={() => this.toggleMeetingStatus(meeting._id)}
                    >
                      {meeting.status === true || meeting.status === "true"
                        ? "Active"
                        : "Inactive"}{" "}
                      {}
                    </button>
                  </td>

                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.togglePopup(meeting)}
                      style={{ marginRight: "5px" }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => this.deleteMeeting(meeting._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <button
                      className="btn btn-info"
                      onClick={() => this.toggleMeetingStatus(meeting._id)}
                    >
                      {meeting.status === "active" ? "Deactivate" : "Activate"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {/* Add pagination logic here */}
        </div>
      </div>
    );
  }
}

const popupContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popupStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  width: "400px",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "5px",
};

const textareaStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "5px",
  height: "100px",
};

export default withRouter(ManageMeetings);

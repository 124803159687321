import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

class AddUpdateRegistrationCategory extends Component {
    state = {
        name: '',
        description: '',
        isEditing: false,
        ERROR: true,
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { name, description } = this.state;
        const token = getAccessToken();
        const id = this.props.match.params.id;

        if (!name || !description) {
            alert('Name and Description are required!');
            return;
        }

        try {
            const payload = { name, description };

            if (id) {
                // Update category
                const response = await Axios.put(`${Constant.apiBasePath}registration-categories/${id}`, payload, {
                    headers: { token },
                });
                if (response.data.status === Constant.statusSuccess) {
                    alert('Category updated successfully!');
                } else {
                    alert(response.data.message);
                }
            } else {
                // Add new category
                const response = await Axios.post(`${Constant.apiBasePath}registration-categories`, payload, {
                    headers: { token },
                });
                if (response.data.status === Constant.statusSuccess) {
                    alert('Category added successfully!');
                } else {
                    alert(response.data.message);
                }
            }
            this.props.history.push('registeration-category');
        } catch (err) {
            console.error('Error while submitting the form:', err);
            alert('Something went wrong. Please try again.');
        }
    };

    fetchCategoryById = async (id) => {
        const token = getAccessToken();
        try {
            const response = await Axios.get(`${Constant.apiBasePath}registration-categories/${id}`, {
                headers: { token },
            });
            const { data } = response.data;
            if (response.data.status === Constant.statusSuccess) {
                console.log('Fetched data:', data); // Check if you get the correct response
                this.setState({ name: data.name, description: data.description, isEditing: true });
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error fetching category:', err);
            alert('Something went wrong. Please try again later.');
        }
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.fetchCategoryById(id);
        }
    }

    render() {
        const { name, description, isEditing } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/registration-categories">Registration Categories</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            {isEditing ? 'Edit' : 'Add'} Registration Category
                                        </li>
                                    </ol>
                                </div>
                                <h4 className="page-title-heading">{isEditing ? 'Edit' : 'Add'} Registration Category</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input
                                                type="text"
                                                id="name"
                                                className="form-control"
                                                value={name} // Bind to state
                                                onChange={this.handleChange} // Handle input changes
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description *</label>
                                            <textarea
                                                id="description"
                                                className="form-control"
                                                rows="4"
                                                value={description} // Bind to state
                                                onChange={this.handleChange} // Handle input changes
                                            ></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-info">
                                            {isEditing ? 'Update' : 'Add'} Category
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddUpdateRegistrationCategory;

import React, { Component } from "react";
import Axios from "axios";
import { getAccessToken } from "../../hooks/AccessToken";
import Constant from "../Constant";
import * as XLSX from "xlsx";

class UserResponse extends Component {
    state = {
        responses: [], 
        groupedResponses: new Map(), 
        loading: true,
        error: null,   
    };

    deleteResponse = async (userId, formId) => {
        const token = getAccessToken();
        try {
            const response = await Axios.delete(`${Constant.apiBasePath}user-responses/delete`, {
                headers: { token },
                data: { userId, formId }, // Axios supports sending body data with DELETE
            });
    
            if (response.data.message === "Response deleted successfully") {
                this.setState((prevState) => {
                    const updatedResponses = prevState.responses.filter(
                        (resp) => !(resp.userId._id === userId && resp.formId === formId)
                    );
                    return { responses: updatedResponses };
                });
                this.fetchResponses(); // Refresh groupedResponses
                alert("Response deleted successfully");
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error deleting response:", error);
            alert("Failed to delete response. Please try again.");
        }
    };
    
    fetchResponses = async () => {
        const { id } = this.props.match.params; 
        const token = getAccessToken();

        try {
            const response = await Axios.get(`${Constant.apiBasePath}user-responses/get/${id}`, {
                headers: { token },
            });

            if (response.data.message === "Responses retrieved successfully") {
                const groupedResponses = new Map();

                {console.log("response",response.data.data)}
                response.data.data.forEach((response) => {
                    const userId = response.userId._id;
                    if (!groupedResponses.has(userId)) {
                        groupedResponses.set(userId, {
                            user: response.userId,
                            answers: [],
                        });
                    }

            
                    response.answers.forEach(answer => {

                        {console.log("shuhws ",answer.otherAnswer)}
                        groupedResponses.get(userId).answers.push({
                            ...answer,
                            otherAnswer: answer.otherAnswer || "",
                        });
                    });
                });

                this.setState({
                    responses: response.data.data,
                    groupedResponses,
                    loading: false,
                });
            } else {
                this.setState({ error: response.data.message, loading: false });
            }
        } catch (err) {
            console.error("Error fetching user responses:", err);
            this.setState({ error: "Failed to fetch user responses. Please try again.", loading: false });
        }
    };

    componentDidMount() {
        this.fetchResponses();
    }

    exportToExcel = () => {
        const { groupedResponses } = this.state;
    
        // Headers for Excel
        const headers = ["Email ID", "User Name"]; // Base headers
        const questionsSet = new Set(); // To collect unique questions
    
        // Step 1: Gather all unique questions
        groupedResponses.forEach(({ answers }) => {
            answers.forEach(answer => {
                questionsSet.add(answer.questionText);
            });
        });
    
        // Add questions as headers
        const questionsArray = Array.from(questionsSet);
        headers.push(...questionsArray);
    
        // Step 2: Prepare rows with user responses
        const rows = [];
        groupedResponses.forEach(({ user, answers }) => {
            const row = Array(headers.length).fill(""); // Initialize row with empty values
            row[0] = user.email; // Set email in the first column
            row[1] = user.name;  // Set user name in the second column
    
            // Fill answers corresponding to questions
            answers.forEach(answer => {
                const questionIndex = headers.indexOf(answer.questionText);
                if (questionIndex !== -1) {
                    row[questionIndex] = Array.isArray(answer.answer)
                        ? answer.answer.join(", ")
                        : answer.answer || answer.otherAnswer || "";
                }
            });
    
            rows.push(row);
        });
    
        // Step 3: Create and export the Excel sheet
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "User Responses");
    
        // Save the Excel file
        XLSX.writeFile(workbook, "UserResponses.xlsx");
    };
    

    render() {
        const { groupedResponses, loading, error } = this.state;
    
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;
    
        // Collect unique questions for the table headers
        const questionsSet = new Set();
        groupedResponses.forEach(({ answers }) => {
            answers.forEach((answer) => {
                questionsSet.add(answer.questionText);
            });
        });
        const questionsArray = Array.from(questionsSet);
    
        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <button
                                onClick={this.exportToExcel}
                                className="btn btn-primary mb-3"
                            >
                                Export to Excel
                            </button>
                            {/* Scrollable container for horizontal scrolling */}
                            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>User ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Profile Picture</th>
                                            {questionsArray.map((question, index) => (
                                                <th key={index}>{question}</th>
                                            ))}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(groupedResponses.values()).map(
                                            ({ user, answers }) => {
                                                // Create a mapping of question to its answer
                                                const answerMap = new Map();
                                                answers.forEach((answer) => {
                                                    answerMap.set(
                                                        answer.questionText,
                                                        Array.isArray(answer.answer)
                                                            ? answer.answer.join(", ")
                                                            : answer.answer || answer.otherAnswer
                                                    );
                                                });
    
                                                return (
                                                    <tr key={user._id}>
                                                        <td>{user._id}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            <img
                                                                src={user.profilePic}
                                                                alt="Profile"
                                                                width="50"
                                                                height="50"
                                                            />
                                                        </td>
                                                        {questionsArray.map((question, index) => (
                                                            <td key={index}>
                                                                {answerMap.get(question) || "-"}
                                                            </td>
                                                        ))}
                                                        <td>
                                                            <button
                                                                onClick={() =>
                                                                    this.deleteResponse(
                                                                        user._id,
                                                                        this.props.match.params.id
                                                                    )
                                                                }
                                                                className="btn btn-danger"
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    
}

export default UserResponse;

import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import '../../css/style.css';
import { Link } from 'react-router-dom';
import { getAccessToken } from '../../hooks/AccessToken';

class RegistrationCategories extends Component {
    state = {
        DataList: [],
        ERROR: true,
    };

    componentDidMount() {
        this.fetchCategories();
    }

    fetchCategories = async () => {
        try {
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + 'registration-categories/get-all', {
                headers: { token },
            });
            const { data } = response.data;
            this.setState({ DataList: data, ERROR: false });
        } catch (err) {
            console.error('Error fetching registration categories:', err);
            this.setState({ ERROR: true });
        }
    };

    deleteCategory = async (id) => {
        const token = getAccessToken();
        try {
            const response = await Axios.delete(Constant.apiBasePath + `registration-categories/${id}`, {
                headers: { token },
            });
            if (response.data.status === Constant.statusSuccess) {
                alert('Category deleted successfully!');
                this.fetchCategories(); // Refresh the list after deletion
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error deleting category:', err);
            alert('Something went wrong. Please try again later.');
        }
    };

    render() {
        const { DataList } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-6">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Registration Categories</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <Link to="/add-registration-category">
                                <button type="button" className="btn btn-info mb-2 mr-2 float-right add-btn">
                                    Add New
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table dt-responsive nowrap">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DataList.length > 0 ? (
                                                DataList.map((category, index) => (
                                                    <tr key={category._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{category.name}</td>
                                                        <td>{category.description}</td>
                                                        <td>
                                                            <Link
                                                                to={`/edit-registration-category/${category._id}`}
                                                                className="btn btn-primary mr-2"
                                                            >
                                                                Edit
                                                            </Link>
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() =>
                                                                    window.confirm('Are you sure you want to delete this category?') &&
                                                                    this.deleteCategory(category._id)
                                                                }
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4">No Categories Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrationCategories;

import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';

class QuotesOfAuthor extends Component {
    state = {
        quotes: [],
        showModal: false,
        newQuote: '',
        newTitle: '',
        newStoryLink: '',
        editQuoteId: null,
        editQuoteText: '',
        editTitle: '',
        editStoryLink: ''
    };

    componentDidMount() {
        this.fetchQuotes();
    }

    fetchQuotes = async () => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + `spotlight/quote/get-by-author/${Id}`, {
                headers: {
                    'token': token
                }
            });
            this.setState({ quotes: response.data.data || [] });
        } catch (error) {
            console.error('Error fetching quotes:', error);
        }
    };

    addQuote = async () => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + `spotlight/quote/${Id}`, {
                quote: this.state.newQuote,
                title: this.state.newTitle,
                storyLink: this.state.newStoryLink,
                authorId: Id
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Quote added successfully.') {
                this.setState({
                    newQuote: '',
                    newTitle: '',
                    newStoryLink: '',
                    showModal: false
                });
                this.fetchQuotes(); // Fetch updated quotes
                window.location.reload(); // Optional: Reload the page
            }
        } catch (error) {
            console.error('Error adding quote:', error);
        }
    };

    editQuote = async () => {
        const { editQuoteId, editQuoteText, editTitle, editStoryLink } = this.state;
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.put(
                `${Constant.apiBasePath}spotlight/quote/${Id}/${editQuoteId}`,
                {
                    quote: editQuoteText,
                    title: editTitle,
                    storyLink: editStoryLink,
                    authorId: Id
                },
                {
                    headers: {
                        'token': token
                    }
                }
            );

            const { data } = response;
            if (data.message === 'Quote updated successfully.') {
                this.setState({
                    editQuoteId: null,
                    editQuoteText: '',
                    editTitle: '',
                    editStoryLink: '',
                    showModal: false
                });
                this.fetchQuotes(); // Fetch updated quotes
                window.location.reload(); // Optional: Reload the page
            }
        } catch (error) {
            console.error('Error updating quote:', error);
        }
    };

    deleteQuote = async (id) => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.delete(Constant.apiBasePath + `spotlight/quote/${Id}/${id}`, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Quote deleted successfully') {
                this.fetchQuotes(); // Fetch updated quotes
                window.location.reload(); // Optional: Reload the page
            }
        } catch (error) {
            console.error('Error deleting quote:', error);
        }
    };

    handleOpenModal = (quote) => {
        if (quote) {
            this.setState({
                editQuoteId: quote._id,
                editQuoteText: quote.quote,
                editTitle: quote.title,
                editStoryLink: quote.storyLink,
                showModal: true
            });
        } else {
            this.setState({ showModal: true });
        }
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            newQuote: '',
            newTitle: '',
            newStoryLink: '',
            editQuoteId: null,
            editQuoteText: '',
            editTitle: '',
            editStoryLink: ''
        });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { quotes, showModal, newQuote, newTitle, newStoryLink, editQuoteText, editTitle, editStoryLink } = this.state;

        return (
            <div className="quotes-container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Quotes</h4>
                                    <button className="btn btn-info" onClick={() => this.handleOpenModal(null)}>Add New Quote</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Quote</th>
                                                <th>Title</th>
                                                <th>Story Link</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quotes.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.quote}</td>
                                                    <td>{item.title || 'No title'}</td>
                                                    <td>
                                                        <a href={item.storyLink || '#'} target="_blank" rel="noopener noreferrer">
                                                            {item.storyLink || 'No story link'}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary" onClick={() => this.handleOpenModal(item)}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                        <button className="btn btn-danger" onClick={() => this.deleteQuote(item._id)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add/Edit Quote"
                    style={{
                        content: {
                            width: '500px',
                            height: 'auto',
                            margin: 'auto',
                        }
                    }}
                >
                    <h2>{this.state.editQuoteId ? 'Edit Quote' : 'Add Quote'}</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="quote">Quote:</label>
                            <textarea
                                id="quote"
                                name={this.state.editQuoteId ? 'editQuoteText' : 'newQuote'}
                                value={this.state.editQuoteId ? editQuoteText : newQuote}
                                onChange={this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Title:</label>
                            <input
                                id="title"
                                name={this.state.editQuoteId ? 'editTitle' : 'newTitle'}
                                value={this.state.editQuoteId ? editTitle : newTitle}
                                onChange={this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="storyLink">Story Link:</label>
                            <input
                                id="storyLink"
                                name={this.state.editQuoteId ? 'editStoryLink' : 'newStoryLink'}
                                value={this.state.editQuoteId ? editStoryLink : newStoryLink}
                                onChange={this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={this.state.editQuoteId ? this.editQuote : this.addQuote}
                            className="btn btn-primary"
                        >
                            {this.state.editQuoteId ? 'Update' : 'Add'}
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn btn-secondary"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default QuotesOfAuthor;

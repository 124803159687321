import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import Constant from "../Constant";
import { getAccessToken } from "../../hooks/AccessToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class AuthorManagement extends Component {
  state = {
    authors: [],
    pageNo: 1,
    length: 0,
    newAuthor: "",
    authorImage: null,
    editingMonth: "",
    editingQuote: null,
    profileLink: "", // New field
    editingAuthor: null,
    showPopup: false,
  };

  componentDidMount() {
    this.fetchAuthors();
  }

  fetchAuthors = async () => {
    try {
      let token = getAccessToken();
      const response = await Axios.get(
        `${Constant.apiBasePath}spotlight/author/get-all`,
        { headers: { token } }
      );
      const { data } = response.data;
      this.setState({
        authors: data || [],
        pageNo: data.pageNo || 1,
        length: data.length || 0,
      });
    } catch (error) {
      console.error("Error fetching authors:", error);
    }
  };

  togglePopup = (author = null, quote = null) => {
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
      newAuthor: author ? author.author : "",
      authorImage: author ? author.imageUrl || null : null,
      editingMonth: author ? author.month || "" : "",
      profileLink: author ? author.profileLink || "" : "", // Populate profileLink
      editingAuthor: author,
      editingQuote: quote,
    }));
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageUpload = async (file) => {
    try {
      if (!file) {
        alert("Please select a file.");
        return;
      }

      let token = getAccessToken();
      let formData = new FormData();
      formData.append("spotlight", file, file.name);
      const response = await Axios.post(
        `${Constant.apiBasePath}spotlight/uploadFileOnS3`,
        formData,
        { headers: { token, "Content-Type": "multipart/form-data" } }
      );

      const { data } = response;
      if (data.status === "Success") {
        this.setState({ authorImage: data.location });
      } else {
        console.error("Error uploading image:", data.message);
        alert("Image upload failed.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Image upload failed.");
    }
  };

  addOrUpdateAuthor = async () => {
    try {
      const {
        newAuthor,
        editingAuthor,
        authorImage,
        editingMonth,
        profileLink,
      } = this.state;

      if (!newAuthor.trim()) {
        alert("Author name is required.");
        return;
      }

      if (profileLink && !/^(http|https):\/\/[^ "]+$/.test(profileLink)) {
        alert("Invalid profile link URL.");
        return;
      }

      let token = getAccessToken();
      let response;

      if (editingAuthor) {
        response = await Axios.patch(
          `${Constant.apiBasePath}spotlight/author/${editingAuthor._id}`,
          {
            author: newAuthor,
            imageUrl: authorImage,
            month: editingMonth,
            profileLink, // Include profileLink
          },
          { headers: { token } }
        );
      } else {
        response = await Axios.post(
          `${Constant.apiBasePath}spotlight/author`,
          {
            author: newAuthor,
            imageUrl: authorImage,
            month: editingMonth,
            profileLink, // Include profileLink
          },
          { headers: { token } }
        );
      }

      const { data } = response;
      if (data.status === "Success" || data.status === "success") {
        this.fetchAuthors();
        this.setState({ showPopup: false });
        alert(
          editingAuthor
            ? "Author updated successfully!"
            : "Author created successfully!"
        );
      } else {
        console.error("Error saving data:", data.message);
        alert("An error occurred while saving data.");
      }
    } catch (error) {
      console.error("Error adding/updating author:", error);
      alert("An unexpected error occurred.");
    }
  };

  deleteAuthor = async (authorId) => {
    try {
      let token = getAccessToken();
      const response = await Axios.delete(
        `${Constant.apiBasePath}spotlight/author/${authorId}`,
        { headers: { token } }
      );
      const { data } = response;
      if (data.status === "success") {
        this.setState((prevState) => ({
          authors: prevState.authors.filter((author) => author._id !== authorId),
        }));
      } else {
        console.error("Error deleting author:", data.message);
      }
    } catch (error) {
      console.error("Error deleting author:", error);
    }
  };

  render() {
    const {
      authors,
      showPopup,
      newAuthor,
      authorImage,
      profileLink,
    } = this.state;

    return (
      <div className="content-page">
        <div className="row">
          <div className="col-6">
            <h4>Author Management</h4>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-info" onClick={() => this.togglePopup()}>
              Add New Author
            </button>
          </div>
        </div>
        {showPopup && (
          <div style={popupContainerStyle}>
            <div style={popupStyle}>
              <h4>{this.state.editingAuthor ? "Edit Author" : "Add Author"}</h4>
              <input
                type="text"
                name="newAuthor"
                placeholder="Author Name"
                value={newAuthor}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="file"
                onChange={(e) => this.handleImageUpload(e.target.files[0])}
                style={inputStyle}
              />
              <input
                type="text"
                name="editingMonth"
                placeholder="Month"
                value={this.state.editingMonth}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              <input
                type="text"
                name="profileLink"
                placeholder="Profile Link"
                value={profileLink}
                onChange={this.handleInputChange}
                style={inputStyle}
              />
              {authorImage && (
                <div>
                  <img
                    src={authorImage}
                    alt="Author"
                    style={{ width: "100px", marginBottom: "15px" }}
                  />
                </div>
              )}
              <button className="btn btn-secondary" onClick={this.togglePopup}>
                Cancel
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={this.addOrUpdateAuthor}
              >
                Save
              </button>
            </div>
          </div>
        )}
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Author</th>
                <th>Image</th>
                <th>Month</th>
                <th>Profile Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {authors.map((author) => (
                <tr key={author._id}>
                  <td>{author.author}</td>
                  <td>
                    {author.imageUrl && (
                      <img
                        src={author.imageUrl}
                        alt="Author"
                        style={{ width: "50px", height: "50px" }}
                      />
                    )}
                  </td>
                  <td>{author.month || "N/A"}</td>
                  <td>
                    {author.profileLink ? (
                      <a href={author.profileLink} target="_blank" rel="noreferrer">
                        View Profile
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.togglePopup(author)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      onClick={() => this.deleteAuthor(author._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(AuthorManagement);

const popupContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popupStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  width: "400px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "15px",
  border: "1px solid #ccc",
  borderRadius: "4px",
};
